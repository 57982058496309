import { FC } from "react";
import { LocationType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import locationAvatar from "images/locationavatar.png";
import { Link } from "react-router-dom";

export interface CardCategoryBox1Props {
  className?: string;
  locationData: LocationType;
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = "",
  locationData,
}) => {
  const { name, href } = locationData;

  return (
    <Link
      to={href}
      className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] cursor-pointer ${className}`}
      data-nc-id="CardCategoryBox1"
      aria-label={`Navigate to ${name}`}
    >
      {/* Avatar Section */}
      <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
        <NcImage src={locationAvatar} containerClassName="absolute inset-0" />
      </div>

      {/* Text Section */}
      <div className="ml-4 flex-grow overflow-hidden">
        <h2 className="text-base font-medium leading-6 text-left text-[#111827]">
          {name}
        </h2>
      </div>
    </Link>
  );
};

export default CardCategoryBox1;

"use client";

import { FC } from "react";

import { useData } from "data/data-provider";
import LocationInput from "components/HeroSearchForm/LocationInput";
import RentalCarDatesRangeInput from "components/HeroSearchForm/(car-search-form)/RentalCarDatesRangeInput";
import RentalCarTimesRangeInput from "components/HeroSearchForm/(car-search-form)/RentalCarTimesRangeInput";
import DropOffLocationInput from "components/HeroSearchForm/DropOffLocationInput";
import StartDateInput from "components/HeroSearchForm/(car-search-form)/StartTimeInput";
import EndDateInput from "components/HeroSearchForm/(car-search-form)/EndTimeInput";
import moment from "moment";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export interface RentalCarSearchFormProps {}

const MobileSearch: FC<RentalCarSearchFormProps> = () => {
  const {
    dropOffLocation,
    setPickupLocation,
    setDropoffLocation,
    setSearchedPickupLocation,
    setSearchedDropoffLocation,
    pickupLocation,
    setDropoffLocationType,
    setSameDropoffLocation,
    dropoffLocationType,
    ourCompany,
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    endPeriod,
    loadingAvailableFleet,
    callAvailableFleetAPI,
  } = useData();
  const navigate = useNavigate();

  const renderRadioBtn = () => {
    return (
      <div className="flex items-center flex-wrap flex-row  border-neutral-100 dark:border-neutral-700 mb-4">
        <div className="grid grid-cols-2 gap-4 w-full bg-[#E5E7EB99] rounded-full">
          <div
            className={`p-0 md:p-4 flex items-center justify-center rounded-full cursor-pointer text-xs md:text-sm xl:text-lg leading-6  text-left m-1 ${
              dropoffLocationType === true
                ? "bg-primary-200 text-white shadow-black/10 opacity-100 font-bold"
                : " text-black"
            }`}
            onClick={(e) => {
              setDropoffLocationType(true);
              setDropoffLocation(pickupLocation);
              setSearchedDropoffLocation(pickupLocation?.name ?? "");
              setSameDropoffLocation(true);
              setPickupLocation(null);
              setDropoffLocation(null);
              setSearchedPickupLocation("");
              setSearchedDropoffLocation("");
            }}
          >
            Same Drop Off
          </div>
          <div
            className={`px-4 py-2 flex items-center justify-center rounded-full text-xs md:text-sm xl:text-lg leading-6 text-left cursor-pointer m-1 ${
              dropoffLocationType === false
                ? "bg-primary-200 text-white  shadow-black/10 font-bold "
                : "text-black"
            }`}
            onClick={() => {
              setDropoffLocationType(false);
              setSameDropoffLocation(false);
              // Reset values
              setPickupLocation(null);
              setDropoffLocation(null);
              setSearchedDropoffLocation("");
              setSearchedPickupLocation("");
            }}
          >
            Different Drop Off
          </div>
        </div>
      </div>
    );
  };

  const isWithinOperationalHours = (
    startHours: string,
    startMinutes: string,
    startPeriod: string,
    endHours: string,
    endMinutes: string,
    endPeriod: string,
    ourCompany: any
  ): boolean => {
    if (ourCompany?.always_operational) {
      return true;
    }

    const convertTo24HourFormat = (hour: number, period: string) => {
      return hour + (period === "PM" && hour !== 12 ? 12 : 0);
    };

    // Company Operational Times
    const [opStartHour, opStartMinute] = ourCompany?.operational_start_time
      .split(":")
      .map(Number);
    const [opEndHour, opEndMinute] = ourCompany?.operational_end_time
      .split(":")
      .map(Number);

    const operationalStart = new Date();
    operationalStart.setHours(opStartHour, opStartMinute, 0, 0);

    const operationalEnd = new Date();
    operationalEnd.setHours(opEndHour, opEndMinute, 0, 0);

    // User Selected Start Time
    const startHour24 = convertTo24HourFormat(Number(startHours), startPeriod);
    const selectedStart = new Date();
    selectedStart.setHours(startHour24, Number(startMinutes), 0, 0);

    // User Selected End Time
    const endHour24 = convertTo24HourFormat(Number(endHours), endPeriod);
    const selectedEnd = new Date();
    selectedEnd.setHours(endHour24, Number(endMinutes), 0, 0);

    // Compare if selected times are within operational hours
    const isStartWithinHours =
      selectedStart >= operationalStart && selectedStart <= operationalEnd;
    const isEndWithinHours =
      selectedEnd >= operationalStart && selectedEnd <= operationalEnd;

    return isStartWithinHours && isEndWithinHours;
  };

  const isDdropOffdifferent = dropoffLocationType === false;

  return (
    <form className="w-full relative p-8 rounded-[35px] shadow-xl dark:shadow-2xl bg-[#FFFFFF99] dark:bg-[#1F2937B2] backdrop:blur-3xl backdrop-blur-3xl z-40">
      {renderRadioBtn()}
      <div
        className={`relative flex flex-col  ${
          isDdropOffdifferent ? "md:grid md:grid-cols-1 md:gap-2" : ""
        }`}
      >
        <div className={`${isDdropOffdifferent ? " " : "w-full my-2"}`}>
          <LocationInput
            placeHolder="Pick Up Location"
            className="w-full bg-white dark:bg-[#1F2937F2] rounded-lg"
            sameDropOffLocation={dropoffLocationType === false}
          />
        </div>
        {isDdropOffdifferent && (
          <div className="my-2">
            <DropOffLocationInput
              placeHolder="Dropoff Location"
              className="w-full bg-white dark:bg-[#1F2937F2] rounded-lg"
              divHideVerticalLineClass="-inset-x-0.5"
            />
          </div>
        )}
      </div>
      <div className="relative flex flex-col">
        <RentalCarDatesRangeInput
          showSingleMonth={true}
          className="w-full bg-white dark:bg-[#1F2937F2] rounded-lg my-2"
        />

        {/* <RentalCarTimesRangeInput
          dropOffLocationType={dropoffLocationType}
          className="w-full bg-white dark:bg-[#1F2937F2] rounded-lg my-2"
        /> */}
      </div>
      <div className="grid grid-cols-2">
        <StartDateInput />
        <EndDateInput />
      </div>
      <div className="cursor-pointer  my-auto mt-4 ">
        <div className="flex justify-center">
          <div
            onClick={() => {
              if (
                !isWithinOperationalHours(
                  startHours,
                  startMinutes,
                  startPeriod,
                  endHours,
                  endMinutes,
                  endPeriod,
                  ourCompany
                )
              ) {
                toast.error(
                  `Please select a pickup and drop-off time between our working hours of ${moment(
                    ourCompany?.operational_start_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")} - ${moment(
                    ourCompany?.operational_end_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}`
                );
                return;
              }

              if (pickupLocation === null) {
                toast.error("Please enter Pick up location");
              } else if (
                pickupLocation !== null
                // validateStartDateTime() === false
              ) {
                callAvailableFleetAPI(navigate);
              }
            }}
            className={`h-14 md:h-16 w-full rounded-[35px] lg:rounded-full bg-primary-200 hover:bg-primary-700 lg:px-5 flex items-center justify-center text-neutral-50 focus:outline-none ${
              startDate && endDate ? "" : "opacity-50 pointer-events-none"
            }`}
          >
            {loadingAvailableFleet ? (
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              <>
                <span className="flex lg:hidden text-lg font-bold">Search Available Cars</span>
                <span className="hidden lg:flex">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4582 22.7503C18.1421 22.7503 22.7498 18.1426 22.7498 12.4587C22.7498 6.77473 18.1421 2.16699 12.4582 2.16699C6.77424 2.16699 2.1665 6.77473 2.1665 12.4587C2.1665 18.1426 6.77424 22.7503 12.4582 22.7503Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.8332 23.8337L21.6665 21.667"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default MobileSearch;

"use client";

import { useRef, useState, useEffect } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useData } from "data/data-provider";

export interface StartTimeInputProps {
  className?: string;
}

const generateTimeSlots = (interval: number = 30): string[] => {
  const timeSlots: string[] = [];
  const periods = ["AM", "PM"];

  for (let period of periods) {
    for (let hour = 0; hour < 12; hour++) {
      const formattedHour = hour === 0 ? 12 : hour; // Convert 0 hour to 12
      for (let minutes = 0; minutes < 60; minutes += interval) {
        const formattedMinutes = minutes.toString().padStart(2, "0");
        timeSlots.push(`${formattedHour}:${formattedMinutes} ${period}`);
      }
    }
  }

  return timeSlots;
};
const timeSlots = generateTimeSlots(30);

const StartTimeInput: React.FC<StartTimeInputProps> = ({ className = "" }) => {
  const [selectedTime, setSelectedTime] = useState("10:00 AM");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openAbove, setOpenAbove] = useState(false);
  const {
    setStartHours,
    setStartMinutes,
    setStartPeriod,
    startHours,
    startMinutes,
    startPeriod,
  } = useData();
  const fieldRef = useRef<HTMLDivElement>(null);

  // Effect to detect dropdown position
  useEffect(() => {
    if (isDropdownOpen && fieldRef.current) {
      const rect = fieldRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      // Open above if there's not enough space below
      setOpenAbove(spaceBelow < 200 && spaceAbove > 200);
    }
  }, [isDropdownOpen]);

  // Effect to update hours, minutes, and period whenever selectedTime changes
  useEffect(() => {
    const [time, timePeriod] = selectedTime.split(" ");
    const [selectedHours, selectedMinutes] = time.split(":");

    setStartHours(selectedHours);
    setStartMinutes(selectedMinutes);
    setStartPeriod(timePeriod);
  }, [selectedTime]);

  return (
    <div className={`relative w-full ${className}`} ref={fieldRef}>
      {/* Input Field */}
      <div
        className="flex items-center justify-between p-4 space-x-3 rounded-lg bg-white shadow-sm cursor-pointer"
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      >
        <div className="flex items-center space-x-3">
          <ClockIcon className="w-5 h-5 text-gray-400" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
          {`${startHours}:${startMinutes} ${startPeriod}`}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Pickup Time"}
          </span>
        </div>
       
      </div>

      {/* Dropdown Popover */}
      {isDropdownOpen && (
        <div
          className={`absolute z-10 w-full max-h-64 bg-white border border-gray-300 rounded-lg shadow-lg overflow-y-auto ${
            openAbove ? "bottom-full mb-2" : "top-full mt-2"
          }`}
        >
          <ul className="py-1 text-sm text-gray-700">
            {timeSlots.map((time) => (
              <li
                key={time}
                className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                  selectedTime === time ? "bg-gray-100 font-bold" : ""
                }`}
                onClick={() => {
                  setSelectedTime(time);
                  setIsDropdownOpen(false);
                }}
              >
                {time}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StartTimeInput;
